import { IButtonProps } from "../../@interfaces"
import { ButtonContainer } from "./styles"
//@ts-ignore
import { TailSpin } from "react-loader-spinner";

const Button: React.FC<IButtonProps> = (props) => {
  return (
    //@ts-ignore
    <ButtonContainer {...props} disabled={props.isLoading}>
      {props.isLoading ?
        <TailSpin
          height={15}
          width={50}
          color="#fff"
        /> : props.label}

    </ButtonContainer>
  )
}

export default Button;


